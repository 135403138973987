import ru from '~/locales/ru.json';
import en from '~/locales/en.json';

export default defineI18nConfig(async () => {
  return {
    locales: [
      { code: 'en', iso: 'en-US', name: 'English', file: 'en.json' },
      { code: 'ru', iso: 'ru-RU', name: 'Русский', file: 'ru.json' },
    ],
    lazy: true,
    langDir: 'locales',
    defaultLocale: 'en',
    locale: 'en',
    fallbackLocale: 'ru',
    messages: {
      ru,
      en,
    },
  };
});
